import { Injectable } from '@angular/core';
import CodiceFiscale from 'codice-fiscale-js';
import  moment from 'moment';
import { enuAnrede, enuGeschlecht, enuGeschlechtSteuernummer, enuMitgliedsPruefung, enuSteuernummerPruefung } from '../globals/enumerationen';
import { AuthService } from './auth.service';

@Injectable()
export class SteuernummerService {

  constructor(
    public authservice: AuthService,
    ) { }


  async checkSteuernummer(steuernummer: string, vorname: string, nachname: string, geschlecht: enuGeschlechtSteuernummer,
                    geburtsdatum: Date, codiceCatasto: string): Promise<enuSteuernummerPruefung> {
    // Ist eingegebene Steuernummer formal korrekt?
    steuernummer = steuernummer.toUpperCase();
    // try {
    //   const  cf = new CodiceFiscale(steuernummer);
    // } catch (err) {
    //   return enuSteuernummerPruefung.FormalInkorrekt;
    // }
    // Stimmt Steuernummer mit eingegebenen Daten überein?
    const datum = moment(geburtsdatum, 'YYYY-MM-DD');
    const paramsSteuer = this.steuerparams(vorname, nachname, geschlecht, geburtsdatum, codiceCatasto);
    try {
      const code = await this.authservice.getUrl('/portal/steuernummerberechnen' + paramsSteuer).catch((data) => {
        return data;
      }).catch((error) => {
        return error;
      });
      // const code = this.getSteuernummerFromData(vorname, nachname, geschlecht,
      // datum, geburtsort, provinz);
      if (code === steuernummer) {
      // Steuernummer korrekt und stimmt mit Daten überein
        return enuSteuernummerPruefung.Korrekt;
      } else {
      // Steuernummer korrekt aber stimmt nicht mit Daten überein
        return enuSteuernummerPruefung.DatenFalsch;
      }
    } catch (err) {
    // Steuernummer korrekt aber Fehler in Daten
    return enuSteuernummerPruefung.DatenFehler;
    }
  }

  // alte Berechnung
  getSteuernummerFromData(vorname: string, nachname: string, geschlecht: string,
                          geburtsdatum: moment.Moment, geburtsort: string, provinz: string): string {
    const name = vorname;
    const surname = nachname;
    if (geschlecht !== 'M') { geschlecht = 'F'; } // (de/ita)
    let gender = null;
    switch(geschlecht) {
      case 'M':
        // eslint-disable-next-line @typescript-eslint/quotes
        gender = "M";
        break;
      case 'F':
        // eslint-disable-next-line @typescript-eslint/quotes
        gender = "F";
        break;
    }
    const d = moment(geburtsdatum, 'DD.MM.YYYY');
    const year  = parseInt(d.format('YYYY'),10);
    const month = parseInt(d.format('M'),10);
    const day   = parseInt(d.format('D'),10);
    const birthplace = geburtsort;
    let birthplaceProvincia;
    if (provinz) {
      birthplaceProvincia = provinz; // 'EE', wenn Geburtsort im Ausland z.B "Germania"
    } else {
      birthplaceProvincia = 'EE'; // 'EE', wenn Geburtsort im Ausland z.B "Germania"
    }
    const cfobject = new CodiceFiscale({name, surname, gender, day, month, year, birthplace, birthplaceProvincia });
    const code = cfobject.toString();
    return code;
  }

  getDataFromSteuernummer(steuernummer: string) {
    const cf = new CodiceFiscale(steuernummer);
    return cf;
  }

  private steuerparams(vorname, nachname, geschlecht, gebdatum, codiceCatasto) {
    let paramsSteuer = '';
    if (vorname) {
      if (paramsSteuer.length > 0) { paramsSteuer = paramsSteuer + '&'; }
      paramsSteuer = paramsSteuer + 'vorname=' + vorname;
    }
    if (nachname) {
      if (paramsSteuer.length > 0) { paramsSteuer = paramsSteuer + '&'; }
      paramsSteuer = paramsSteuer + 'nachname=' + nachname;
    }
    // if (geschlecht) {
    //   if (paramsSteuer.length > 0) { paramsSteuer = paramsSteuer + '&'; }
      paramsSteuer = paramsSteuer + '&geschlecht=' + geschlecht;
    // }
    if (gebdatum) {
      if (paramsSteuer.length > 0) { paramsSteuer = paramsSteuer + '&'; }
      const m = moment(gebdatum);
      paramsSteuer = paramsSteuer + 'gebdatum=' +  m.format('YYYY-MM-DD');
    }
    if (codiceCatasto) {
      if (paramsSteuer.length > 0) { paramsSteuer = paramsSteuer + '&'; }
      paramsSteuer = paramsSteuer + 'codiceCatasto=' + codiceCatasto;
    }
    if (paramsSteuer.length > 0) paramsSteuer = '?' + paramsSteuer;
    return paramsSteuer;
  }

}
